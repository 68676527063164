<template>
<div>
  <h1>정답 여부에 따른 결과 값을 테스트 해 봅시다</h1>
  <h3>체크 하시면 해당 문제는 정답 처리 헙니다</h3>
  <h3>20 ~ 23 은 말하기 문제 입니다. 1~4 까지의 숫자로 넣어 주세요</h3>
  <div>
    <ul>
      <li>
        <div class="title">유형 1 (듣기)</div>
        <div v-for="index in quizTypes.type1" :key="index" class="temp">
          <span>{{index}}</span>
          <input type="checkbox" v-model="quizs" :value="index"/>
        </div>
      </li>
      <li>
        <div class="title">유형 2 (어휘)</div>
        <div v-for="index in quizTypes.type2" :key="index" class="temp">
          <span>{{index}}</span>
          <input type="checkbox" v-model="quizs" :value="index"/>
        </div>
      </li>
      <li>
        <div class="title">유형 4 (듣기)</div>
        <div v-for="index in quizTypes.type3" :key="index" class="temp">
          <span>{{index}}</span>
          <input type="checkbox" v-model="quizs" :value="index"/>
        </div>
      </li>
      <li>
        <div class="title">유형 5 (읽기)</div>
        <div v-for="index in quizTypes.type4" :key="index" class="temp">
          <span>{{index}}</span>
          <input type="checkbox" v-model="quizs" :value="index"/>
        </div>
      </li>
      <li>
        <div class="title">유형 3 (읽기)</div>
        <div v-for="index in quizTypes.type5" :key="index" class="temp">
          <span>{{index}}</span>
          <input type="checkbox" v-model="quizs" :value="index"/>
        </div>
      </li>
      <li>
        <div class="title">유형 7 (듣기)</div>
        <div v-for="index in quizTypes.type6" :key="index" class="temp">
          <span>{{index}}</span>
          <input type="checkbox" v-model="quizs" :value="index"/>
        </div>
      </li>
      <li>
        <div class="title">유형 6 (쓰기)</div>
        <div v-for="index in quizTypes.type7" :key="index" class="temp">
          <span>{{index}}</span>
          <input type="checkbox" v-model="quizs" :value="index"/>
        </div>
      </li>
      <li>
        <div class="title">유형 9 (말하기)</div>
        <span>20</span>
        <input type="text" v-model="quiz20" size="1">
      </li>
      <li>
        <div class="title">유형 10 (말하기)</div>
        <div class="temp">
          <span>21</span>
          <input type="text" v-model="quiz21" size="1">
        </div>
        <div class="temp">
          <span>22</span>
          <input type="text" v-model="quiz22" size="1">
        </div>
        <div class="temp">
          <span>23</span>
          <input type="text" v-model="quiz23" size="1">
        </div>
      </li>
    </ul>
  </div>
  <div>
    <button @click="test">점수 확인</button>
  </div>
  <div class="result">
    <ul>
      <li>
        <div>듣기 점수: {{lc}}</div>
      </li>
      <li>
        <div>읽기 점수: {{rc}}</div>
      </li>
      <li>
        <div>쓰기 점수: {{wc}}</div>
      </li>
      <li>
        <div>어휘 점수: {{word}}</div>
      </li>
      <li>
        <div>말하기 점수: {{sp}}</div>
      </li>
      <li>
        <div>총점: {{lc+rc+wc+word+sp}}</div>
      </li>
    </ul>
  </div>
  <div class="advice">{{advice}}</div>
  <div v-if="isLoading" class="loading-container">
    <div class="loading">
      <FadeLoader />
    </div>
  </div>
</div>
</template>

<script>
import config from '@/global/Config'
import axios from 'axios'
import FadeLoader from 'vue-spinner/src/FadeLoader.vue'

export default {
  name: "QuizType1",
  components: {
    FadeLoader,
  },
  data() {
    return {
      quizTypes: {
        type1: [1],
        type2: [2, 3, 4],
        type3: [5, 6, 7],
        type4: [8, 9, 10],
        type5: [11, 12, 13],
        type6: [14, 15, 16],
        type7: [17, 18, 19]
      },
      quizs: [],
      quiz20: 4,
      quiz21: 4,
      quiz22: 4,
      quiz23: 4,
      isLoading: false,
      lc: 0,
      rc: 0,
      wc: 0,
      word: 0,
      sp: 0,
      advice: ''
    }
  },
  computed: {
    apiUrl() {
      return `${config.api_server_url}/v1/process`
    },
  },
  async mounted() {
    let count = 1;
    for (count ; count < 20 ; count += 1) {
      this.quizs.push(count);
    }
  },
  methods: {
    async process(param) {
      this.isLoading = true

      await axios.post(this.apiUrl, param)
        .then(response => {
          if (response.status === 200) {
            console.log(response.data)
            this.lc = response.data.lc
            this.rc = response.data.rc
            this.wc = response.data.wc
            this.word = response.data.word
            this.sp = response.data.sp
          }

          this.getAdvice()

        })
        .catch(error => {
          console.log(error)

          this.isLoading = false
        })
    },
    async getAdvice() {
      let index = Math.floor(Math.random() * 101)
      await axios.get(`https://api.adviceslip.com/advice/${index}`)
        .then(response => {
          if (response.status === 200) {
            console.log(response.data)
            this.advice = response.data.slip.advice
          }

          this.isLoading = false
        })
        .catch(error => {
          console.log(error)

          this.isLoading = false
        })
    },
    getParamBase() {
      return {
        index1: 0,
        index2: 0,
        index3: 0,
        index4: 0,
        index5: 0,
        index6: 0,
        index7: 0,
        index8: 0,
        index9: 0,
        index10: 0,
        index11: 0,
        index12: 0,
        index13: 0,
        index14: 0,
        index15: 0,
        index16: 0,
        index17: 0,
        index18: 0,
        index19: 0,
        index20: this.quiz20,
        index21: this.quiz21,
        index22: this.quiz22,
        index23: this.quiz23,
      }
    },
    test() {
      const param = this.getParamBase()

      this.quizs.map(item => {
        switch (item) {
          case 1:
            param.index1 = 1;
            break
          case 2:
            param.index2 = 1;
            break
          case 3:
            param.index3 = 1;
            break
          case 4:
            param.index4 = 1;
            break
          case 5:
            param.index5 = 1;
            break
          case 6:
            param.index6 = 1;
            break
          case 7:
            param.index7 = 1;
            break
          case 8:
            param.index8 = 1;
            break
          case 9:
            param.index9 = 1;
            break
          case 10:
            param.index10 = 1;
            break
          case 11:
            param.index11 = 1;
            break
          case 12:
            param.index12 = 1;
            break
          case 13:
            param.index13 = 1;
            break
          case 14:
            param.index14 = 1;
            break
          case 15:
            param.index15 = 1;
            break
          case 16:
            param.index16 = 1;
            break
          case 17:
            param.index17 = 1;
            break
          case 18:
            param.index18 = 1;
            break
          case 19:
            param.index19 = 1;
            break
        }
      });

      console.log(param)
      this.process(param)
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Tangerine&display=swap');

.advice {
  font-family: 'Tangerine', cursive;
  font-size: 4rem;
  margin-top: 2rem;
}

.loading {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 9999px;
}

ul {
  list-style-type: none;
}

li:before {
  content: none;
}

li {
  margin-top: 1rem;
  margin-left: 1rem;
  padding: .5rem;
  border: 1px solid #dddddd;
  border-radius: 1rem;
  display: inline-block;
  background: aliceblue;
}

.temp {
  display: inline-block;
  margin-left: 1rem;
}

.title {
  font-size: 1rem;
  margin-bottom: .4rem;
}

button:hover {
  cursor: pointer;
}

table {
  border: 1px solid #eee;
  border-collapse: collapse;
  margin: .4rem;
}

td {
  border: 1px solid;
  padding: .5rem;
}
</style>
