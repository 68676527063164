<template>
<div>
  <h1>퀴즈 유형 10</h1>
  <div>
    <ul>
      <li v-for="(quiz, index) in quizs" :key="index">
        <table>
          <tr><td>유형</td><td>{{quiz.type}}</td></tr>
          <tr><td>번호</td><td>{{quiz.number}}</td></tr>
          <tr><td>정답</td><td>{{quiz.answer}}</td></tr>
          <tr><td>문제</td><td>{{quiz.question}}</td></tr>
          <tr><td>힌트 1</td><td>{{quiz.hint1}}</td></tr>
          <tr><td>힌트 2</td><td>{{quiz.hint2}}</td></tr>
        </table>
      </li>
    </ul>
  </div>
  <div v-if="isLoading" class="loading-container">
    <div class="loading">
      <FadeLoader />
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import FadeLoader from 'vue-spinner/src/FadeLoader.vue'
import config from '@/global/Config'

export default {
  name: "QuizType10",
  components: {
    FadeLoader,
  },
  data() {
    return {
      quizs: [],
      isLoading: false,
    }
  },
  computed: {
    apiUrl() {
      return `${config.api_server_url}/quiz/all/10/${config.id_number}`
    }
  },
  async mounted() {
    this.isLoading = true
    await axios.get(this.apiUrl)
      .then(response => {
        if (response.status === 200) {
          console.log(response.data)
          // this.quizs.push(response.data)
          this.quizs = response.data
        }

        this.isLoading = false
      })
      .catch(error => {
        console.log(error)

        this.isLoading = false
      })
  },
  methods: {
    changeToeicScore(index, quizNumber, score) {
      this.quizs[index].score = score
      this.$emit('updateToeicScore', 10, quizNumber, score)
    }
  }
}
</script>

<style scoped>
.loading {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 9999px;
}

ul {
  list-style-type: none;
}

li:before {
  content: none;
}

table {
  border: 1px solid #eee;
  border-collapse: collapse;
  margin: .4rem;
}

td {
  border: 1px solid;
  padding: .5rem;
}
</style>
