<template>
  <div>
    <h1>퀴즈 유형 5</h1>
    <h3>대화 중 빈 칸에 들어갈 알맞은 문장을 보기에서 선택하세요.</h3>
    <div>
      <ul>
        <li v-for="(quiz, index) in quizs" :key="index">
          <table :class="{ disabled: quiz.temp !== '' }">
            <tr>
              <td>유형</td>
              <td>{{ quiz.type }}</td>
            </tr>
            <tr>
              <td>번호</td>
              <td>{{ quiz.number }}</td>
            </tr>
            <tr>
              <td>대화 1</td>
              <td class="text-block">{{ quiz.dialog1 }}</td>
            </tr>
            <tr>
              <td>대화 2</td>
              <td class="text-block">{{ quiz.dialog2 }}</td>
            </tr>
            <tr>
              <td>대화 3</td>
              <td class="text-block">{{ quiz.dialog3 }}</td>
            </tr>
            <tr>
              <td>대화 4</td>
              <td class="text-block">{{ quiz.dialog4 }}</td>
            </tr>
            <tr>
              <td>보기 1</td>
              <td
                class="input-class"
                @click="setAnswer(index, quiz.number, quiz.exams[0])"
              >
                <input
                  type="radio"
                  :name="'answer' + quiz.number"
                  v-model="quiz.temp"
                  :value="quiz.exams[0]"
                  @change="setAnswer(index, quiz.number, quiz.exams[0])"
                />{{ quiz.exams[0] }}
              </td>
            </tr>
            <tr>
              <td>보기 2</td>
              <td
                class="input-class"
                @click="setAnswer(index, quiz.number, quiz.exams[1])"
              >
                <input
                  type="radio"
                  :name="'answer' + quiz.number"
                  v-model="quiz.temp"
                  :value="quiz.exams[1]"
                  @change="setAnswer(index, quiz.number, quiz.exams[1])"
                />{{ quiz.exams[1] }}
              </td>
            </tr>
            <tr>
              <td>보기 3</td>
              <td
                class="input-class"
                @click="setAnswer(index, quiz.number, quiz.exams[2])"
              >
                <input
                  type="radio"
                  :name="'answer' + quiz.number"
                  v-model="quiz.temp"
                  :value="quiz.exams[2]"
                  @change="setAnswer(index, quiz.number, quiz.exams[2])"
                />{{ quiz.exams[2] }}
              </td>
            </tr>
            <tr>
              <td>보기 4</td>
              <td
                class="input-class"
                @click="setAnswer(index, quiz.number, quiz.exams[3])"
              >
                <input
                  type="radio"
                  :name="'answer' + quiz.number"
                  v-model="quiz.temp"
                  :value="quiz.exams[3]"
                  @change="setAnswer(index, quiz.number, quiz.exams[3])"
                />{{ quiz.exams[3] }}
              </td>
            </tr>
            <tr>
              <td>보기 5</td>
              <td class="input-class" @click="setAnswer(index, quiz.number, '???')">
                <input
                  type="radio"
                  :name="'answer' + quiz.number"
                  v-model="quiz.temp"
                  :value="'???'"
                  @change="setAnswer(index, quiz.number, '???')"
                />모름
              </td>
            </tr>
          </table>
        </li>
      </ul>
    </div>
    <div v-if="isLoading" class="loading-container">
      <div class="loading">
        <FadeLoader />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import config from "@/global/Config";

export default {
  name: "QuizType5",
  components: {
    FadeLoader,
  },
  props: {
    token: String,
  },
  data() {
    return {
      quizs: [],
      isLoading: false,
    };
  },
  computed: {
    apiUrl() {
      return `${config.api_server_url}/test`;
    },
    param() {
      return {
        token: this.token,
        quiz_type: 5,
      };
    },
  },
  async mounted() {
    this.isLoading = true;

    let shuffle = (array) => {
      array.sort(() => Math.random() - 0.5);
    };

    await axios
      .post(this.apiUrl, this.param)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          this.quizs = response.data;

          this.quizs.map((quiz) => {
            let temp = [];
            temp.push(quiz.answer);
            temp.push(quiz.exam1);
            temp.push(quiz.exam2);
            temp.push(quiz.exam3);

            shuffle(temp);

            quiz["exams"] = temp;
          });

          this.updateNoTouchQuizCount();
        }

        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error);

        this.isLoading = false;
      });
  },
  methods: {
    updateNoTouchQuizCount() {
      this.$store.commit(
        "setCountType5",
        this.quizs.filter((item) => item.temp.length === 0).length
      );
    },
    changeToeicScore(index, quizNumber, score) {
      this.quizs[index].score = score;
      this.$emit("updateToeicScore", 5, quizNumber, score);
    },
    putAnswer(index, quizNumber, answer) {
      this.quizs[index].temp = answer;
      this.$emit("putAnswer", 5, quizNumber, answer, "");
    },
    setAnswer(index, quizNumber, answer) {
      this.quizs[index].temp = answer;
      this.$emit("setAnswer", this.token, 5, quizNumber, answer);
      this.updateNoTouchQuizCount();
    },
  },
};
</script>

<style scoped>
.loading {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 9999px;
}

ul {
  list-style-type: none;
}

li:before {
  content: none;
}

table {
  border: 1px solid #eee;
  border-collapse: collapse;
  margin: 0.4rem;
}

td {
  border: 1px solid red;
  padding: 0.5rem;
}

.text-block {
  text-align: left;
}

td.input-class {
  display: flex;
  align-items: center;
  justify-content: center;
}
td.input-class:hover {
  cursor: pointer;
}

table.disabled td {
  border: 1px solid green;
}
</style>
