<template>
  <div>
    <h1>퀴즈 유형 4</h1>
    <h3>음성을 끝까지 듣고 알맞은 답변을 선택하세요.</h3>
    <div>
      <ul>
        <li v-for="(quiz, index) in quizs" :key="index">
          <table :class="{ disabled: quiz.temp !== '' }">
            <tr>
              <td>유형</td>
              <td>{{ quiz.type }}</td>
            </tr>
            <tr>
              <td>번호</td>
              <td>{{ quiz.number }}</td>
            </tr>
            <tr>
              <td>음성 파일</td>
              <td class="input-class">
                <audio controls>
                  <source :src="quiz.question_audio_file" type="audio/mpeg" />
                </audio>
              </td>
            </tr>
            <tr>
              <td>보기 1</td>
              <td class="input-class" @click="setAnswer(index, quiz.number, 'A')">
                <input
                  type="radio"
                  :name="'answer' + quiz.number"
                  v-model="quiz.temp"
                  value="A"
                  @change="setAnswer(index, quiz.number, 'A')"
                />A
              </td>
            </tr>
            <tr>
              <td>보기 2</td>
              <td class="input-class" @click="setAnswer(index, quiz.number, 'B')">
                <input
                  type="radio"
                  :name="'answer' + quiz.number"
                  v-model="quiz.temp"
                  value="B"
                  @change="setAnswer(index, quiz.number, 'B')"
                />B
              </td>
            </tr>
            <tr>
              <td>보기 3</td>
              <td class="input-class" @click="setAnswer(index, quiz.number, 'C')">
                <input
                  type="radio"
                  :name="'answer' + quiz.number"
                  v-model="quiz.temp"
                  value="C"
                  @change="setAnswer(index, quiz.number, 'C')"
                />C
              </td>
            </tr>
            <tr>
              <td>보기 4</td>
              <td class="input-class" @click="setAnswer(index, quiz.number, '???')">
                <input
                  type="radio"
                  :name="'answer' + quiz.number"
                  v-model="quiz.temp"
                  :value="'???'"
                  @change="setAnswer(index, quiz.number, '???')"
                />모름
              </td>
            </tr>
          </table>
        </li>
      </ul>
    </div>
    <div v-if="isLoading" class="loading-container">
      <div class="loading">
        <FadeLoader />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import config from "@/global/Config";

export default {
  name: "QuizType4",
  components: {
    FadeLoader,
  },
  props: {
    token: String,
  },
  data() {
    return {
      quizs: [],
      isLoading: false,
    };
  },
  computed: {
    apiUrl() {
      return `${config.api_server_url}/test`;
    },
    param() {
      return {
        token: this.token,
        quiz_type: 4,
      };
    },
  },
  async mounted() {
    this.isLoading = true;
    await axios
      .post(this.apiUrl, this.param)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          // this.quizs.push(response.data)
          this.quizs = response.data;
          this.updateNoTouchQuizCount();
        }

        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error);

        this.isLoading = false;
      });
  },
  methods: {
    updateNoTouchQuizCount() {
      this.$store.commit(
        "setCountType4",
        this.quizs.filter((item) => item.temp.length === 0).length
      );
    },
    changeToeicScore(index, quizNumber, score) {
      this.quizs[index].score = score;
      this.$emit("updateToeicScore", 4, quizNumber, score);
    },
    setAnswer(index, quizNumber, answer) {
      this.quizs[index].temp = answer;
      this.$emit("setAnswer", this.token, 4, quizNumber, answer);
      this.updateNoTouchQuizCount();
    },
  },
};
</script>

<style scoped>
.loading {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 9999px;
}

ul {
  list-style-type: none;
}

li:before {
  content: none;
}

table {
  border: 1px solid #eee;
  border-collapse: collapse;
  margin: 0.4rem;
}

td {
  border: 1px solid red;
  padding: 0.5rem;
}

td.input-class {
  display: flex;
  align-items: center;
  justify-content: center;
}
td.input-class:hover {
  cursor: pointer;
}

table.disabled td {
  border: 1px solid green;
}
</style>
