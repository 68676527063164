import { createStore } from 'vuex';

// [store 데이터 설정 실시]
const store = createStore ({
  state: { // [변수들의 집합]
    type1: 0,
    type2: 0,
    type3: 0,
    type4: 0,
    type5: 0,
    type6: 0,
    type7: 0,
  },
  getters: { // [state의 변수들을 get 호출]
    countType1(state){
        return state.type1;
    },
    countType2(state){
        return state.type2;
    },
    countType3(state){
        return state.type3;
    },
    countType4(state){
        return state.type4;
    },
    countType5(state){
        return state.type5;
    },
    countType6(state){
        return state.type6;
    },
    countType7(state){
        return state.type7;
    },
  },
  mutations: { // [변수들을 조작하는 함수들]
    setCountType1(state, count){
        state.type1 = count;
    },
    setCountType2(state, count){
        state.type2 = count;
    },
    setCountType3(state, count){
        state.type3 = count;
    },
    setCountType4(state, count){
        state.type4 = count;
    },
    setCountType5(state, count){
        state.type5 = count;
    },
    setCountType6(state, count){
        state.type6 = count;
    },
    setCountType7(state, count){
        state.type7 = count;
    },
  },
  actions: { // [비동기 처리를 하는 함수들]
  },
});

export default store;