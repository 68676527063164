<template>
  <div class="hello">
    <h1 v-if="nowQuizType !== 100">퀴즈 유형 리스트</h1>
    <div v-if="nowQuizType !== 100">
      <ul>
        <li
          @click="chageQuizType(3)"
          :class="{ 'selected-quiz-type': nowQuizType === 3 }"
        >
          유형 3
        </li>
        <li
          @click="chageQuizType(4)"
          :class="{ 'selected-quiz-type': nowQuizType === 4 }"
        >
          유형 4
        </li>
        <li
          @click="chageQuizType(5)"
          :class="{ 'selected-quiz-type': nowQuizType === 5 }"
        >
          유형 5
        </li>
        <li
          @click="chageQuizType(6)"
          :class="{ 'selected-quiz-type': nowQuizType === 6 }"
        >
          유형 6
        </li>
        <li
          @click="chageQuizType(7)"
          :class="{ 'selected-quiz-type': nowQuizType === 7 }"
        >
          유형 7
        </li>
        <li
          v-if="false"
          @click="chageQuizType(2)"
          :class="{ 'selected-quiz-type': nowQuizType === 2 }"
        >
          유형 2
        </li>
        <li
          v-if="false"
          @click="chageQuizType(1)"
          :class="{ 'selected-quiz-type': nowQuizType === 1 }"
        >
          유형 1
        </li>
      </ul>
    </div>
    <Login v-if="nowQuizType === 100" @setToken="setToken" />
    <QuizType1
      v-if="nowQuizType === 1"
      @updateToeicScore="updateToeicScore"
      @putAnswer="putAnswer"
      @setAnswer="setAnswer"
      :token="token"
    ></QuizType1>
    <QuizType2
      v-if="nowQuizType === 2"
      @updateToeicScore="updateToeicScore"
      @putAnswer="putAnswer"
      @setAnswer="setAnswer"
      :token="token"
    ></QuizType2>
    <QuizType3
      v-if="nowQuizType === 3"
      @updateToeicScore="updateToeicScore"
      @putAnswer="putAnswer"
      @setAnswer="setAnswer"
      :token="token"
    ></QuizType3>
    <QuizType4
      v-if="nowQuizType === 4"
      @updateToeicScore="updateToeicScore"
      @putAnswer="putAnswer"
      @setAnswer="setAnswer"
      :token="token"
    ></QuizType4>
    <QuizType5
      v-if="nowQuizType === 5"
      @updateToeicScore="updateToeicScore"
      @putAnswer="putAnswer"
      @setAnswer="setAnswer"
      :token="token"
    ></QuizType5>
    <QuizType6
      v-if="nowQuizType === 6"
      @updateToeicScore="updateToeicScore"
      @putAnswer="putAnswer"
      @setAnswer="setAnswer"
      :token="token"
    ></QuizType6>
    <QuizType7
      v-if="nowQuizType === 7"
      @updateToeicScore="updateToeicScore"
      @putAnswer="putAnswer"
      @setAnswer="setAnswer"
      :token="token"
    ></QuizType7>
    <QuizType8
      v-if="nowQuizType === 8"
      @updateToeicScore="updateToeicScore"
      @putAnswer="putAnswer"
      @setAnswer="setAnswer"
      :token="token"
    ></QuizType8>
    <QuizType9
      v-if="nowQuizType === 9"
      @updateToeicScore="updateToeicScore"
      @putAnswer="putAnswer"
      @setAnswer="setAnswer"
      :token="token"
    ></QuizType9>
    <QuizType10
      v-if="nowQuizType === 10"
      @updateToeicScore="updateToeicScore"
      @putAnswer="putAnswer"
      @setAnswer="setAnswer"
      :token="token"
    ></QuizType10>
    <Simulation
      v-if="nowQuizType === 0"
      @updateToeicScore="updateToeicScore"
      @putAnswer="putAnswer"
    ></Simulation>
    <div v-if="isLoading" class="loading-container">
      <div class="loading">
        <FadeLoader />
      </div>
    </div>
  </div>
</template>

<script>
import QuizType1 from "@/components/QuizType1.vue";
import QuizType2 from "@/components/QuizType2.vue";
import QuizType3 from "@/components/QuizType3.vue";
import QuizType4 from "@/components/QuizType4.vue";
import QuizType5 from "@/components/QuizType5.vue";
import QuizType6 from "@/components/QuizType6.vue";
import QuizType7 from "@/components/QuizType7.vue";
import QuizType8 from "@/components/QuizType8.vue";
import QuizType9 from "@/components/QuizType9.vue";
import QuizType10 from "@/components/QuizType10.vue";
import config from "@/global/Config";
import axios from "axios";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import Simulation from "@/components/Simulation.vue";
import Login from "@/components/Login.vue";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
    url_token: String,
  },
  components: {
    Login,
    QuizType1,
    QuizType2,
    QuizType3,
    QuizType4,
    QuizType5,
    QuizType6,
    QuizType7,
    QuizType8,
    QuizType9,
    QuizType10,
    FadeLoader,
    Simulation,
  },
  data() {
    return {
      nowQuizType: 100,
      isLoading: false,
      token: "",
    };
  },
  computed: {
    setAnswerUrl() {
      return `${config.api_server_url}/answer`;
    },
  },
  mounted() {
    console.log(this.url_token);
    if (this.url_token) {
      this.token = this.url_token;
      this.nowQuizType = 3;
    }
  },
  methods: {
    chageQuizType(quizType1) {
      let count = this.$store.getters.countType1;

      if (this.nowQuizType === 1) count = this.$store.getters.countType1;
      if (this.nowQuizType === 2) count = this.$store.getters.countType2;
      if (this.nowQuizType === 3) count = this.$store.getters.countType3;
      if (this.nowQuizType === 4) count = this.$store.getters.countType4;
      if (this.nowQuizType === 5) count = this.$store.getters.countType5;
      if (this.nowQuizType === 6) count = this.$store.getters.countType6;
      if (this.nowQuizType === 7) count = this.$store.getters.countType7;

      if (count > 0) {
        alert("모든 문제를 풀어 주세요");
        return;
      }

      this.nowQuizType = quizType1;
    },
    setToken(token) {
      this.token = token;
      this.nowQuizType = 3;
    },
    putUrl(sheet) {
      return `${config.api_server_url}/quiz/score/${sheet}`;
    },
    putAnswerUrl(sheet) {
      return `${config.api_server_url}/quiz/answer/${sheet}`;
    },
    async updateToeicScore(sheet, quizNumber, score) {
      console.log(quizNumber);
      console.log(score);

      this.isLoading = true;

      await axios
        .put(this.putUrl(sheet), {
          quiz_number: quizNumber,
          score: score,
          id_number: config.id_number,
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data.result);
          }

          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);

          this.isLoading = false;
        });
    },
    async putAnswer(sheet, quizNumber, answer1, answer2) {
      console.log(quizNumber);
      console.log(answer1);
      console.log(answer2);

      this.isLoading = true;

      await axios
        .put(this.putAnswerUrl(sheet), {
          quiz_number: quizNumber,
          id_number: config.id_number,
          answer1: answer1,
          answer2: answer2,
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data.result);
          }

          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);

          this.isLoading = false;
        });
    },
    async setAnswer(token, quizType, quizIndex, answer) {
      console.log(
        `token: ${token}, quiz_type: ${quizType}, quizIndex: ${quizIndex}, answer: ${answer}`
      );

      this.isLoading = true;

      await axios
        .post(this.setAnswerUrl, {
          token: token,
          quiz_type: quizType,
          quiz_index: quizIndex,
          answer: answer,
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data.result);
          }

          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);

          this.isLoading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
li:hover {
  cursor: pointer;
  color: #42b983;
}
.selected-quiz-type {
  color: #42b983;
}
a {
  color: #42b983;
}

.loading {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 9999px;
}

td {
  display: flex;
  align-items: center;
}
</style>
