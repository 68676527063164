<template>
  <div>
    <h1>퀴즈 유형 6</h1>
    <h3>
      문제에 해당하는 문장을 주어진 단어를 이용하여 완성하세요. (단어를 클릭하거나 드래그로 이동하여)
    </h3>
    <div>
      <ul>
        <li v-for="(quiz, index) in quizs" :key="index">
          <table :class="{ disabled: quiz.temp.length > 0 }">
            <tr>
              <td>유형</td>
              <td>{{ quiz.type }}</td>
            </tr>
            <tr>
              <td>번호</td>
              <td>{{ quiz.number }}</td>
            </tr>
            <tr>
              <td>문제</td>
              <td>{{ quiz.question }}</td>
            </tr>
            <tr>
              <td>제시 단어</td>
              <td>
                <draggable
                  :list="quiz.exam"
                  class="list-group"
                  :group="'a' + index"
                  item-key="name"
                >
                  <template #item="{ element }">
                    <span class="list-group-item-from item" @click="add(index, element)">
                      {{ element }}
                    </span>
                  </template>
                </draggable>
              </td>
            </tr>
            <tr>
              <td>제출할 답</td>
              <td class="answer">
                <draggable
                  :list="quiz.temp"
                  class="list-group"
                  :group="'a' + index"
                  item-key="name"
                  @change="changeAnswer(index)"
                >
                  <template #item="{ element }">
                    <span class="list-group-item-to item" @click="cancel(index, element)">
                      {{ element }}
                    </span>
                  </template>
                </draggable>
              </td>
            </tr>
          </table>
        </li>
      </ul>
    </div>
    <div v-if="isLoading" class="loading-container">
      <div class="loading">
        <FadeLoader />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import config from "@/global/Config";
import draggable from "vuedraggable";

export default {
  name: "QuizType6",
  components: {
    FadeLoader,
    draggable,
  },
  props: {
    token: String,
  },
  data() {
    return {
      quizs: [],
      isLoading: false,
    };
  },
  computed: {
    apiUrl() {
      return `${config.api_server_url}/test`;
    },
    param() {
      return {
        token: this.token,
        quiz_type: 6,
      };
    },
  },
  async mounted() {
    this.isLoading = true;

    let shuffle = (array) => {
      array.sort(() => Math.random() - 0.5);
    };

    await axios
      .post(this.apiUrl, this.param)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          // this.quizs.push(response.data)
          this.quizs = response.data;
          this.quizs.map((quiz) => {
            shuffle(quiz.exam);
          });
          this.updateNoTouchQuizCount();
        }

        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error);

        this.isLoading = false;
      });
  },
  methods: {
    changeToeicScore(index, quizNumber, score) {
      this.quizs[index].score = score;
      this.$emit("updateToeicScore", 6, quizNumber, score);
    },
    cancel(index, voca) {
      console.log(voca);
      this.quizs[index].temp = this.quizs[index].temp.filter((item) => item !== voca);
      this.quizs[index].exam.push(voca);

      this.changeAnswer(index);
    },
    add(index, voca) {
      console.log(voca);
      this.quizs[index].exam = this.quizs[index].exam.filter((item) => item !== voca);
      this.quizs[index].temp.push(voca);

      this.changeAnswer(index);
    },
    changeAnswer(index) {
      const quiz = this.quizs[index];
      const answer = quiz.temp.join("**");
      this.setAnswer(index, answer);
    },
    setAnswer(index, answer) {
      console.log(answer);
      this.$emit("setAnswer", this.token, 6, this.quizs[index].number, answer);
      this.updateNoTouchQuizCount();
    },
    updateNoTouchQuizCount() {
      this.$store.commit(
        "setCountType6",
        this.quizs.filter((item) => item.temp.length === 0).length
      );
    },
  },
};
</script>

<style scoped>
.loading {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 9999px;
}

ul {
  list-style-type: none;
}

li:before {
  content: none;
}

table {
  border: 1px solid #eee;
  border-collapse: collapse;
  margin: 0.4rem;
}

td {
  border: 1px solid red;
  padding: 0.5rem;
}

.list-group-item-from {
  padding: 0.2rem 0.5rem;
}

.list-group-item-to {
  padding: 0.2rem;
  text-decoration: underline;
  font-size: 1.1rem;
}

.item:hover {
  cursor: pointer;
  border: 1px solid #aaa;
  border-radius: 1rem;
  background-color: rgba(100, 176, 255, 0.2);
}

.answer {
  background-color: rgba(150, 150, 150, 0.1);
}

table.disabled td {
  border: 1px solid green;
}
</style>
