<template>
  <div>
    <h1>퀴즈 유형 3</h1>
    <h3>문제의 빈칸에 들어갈 알맞은 단어를 차례대로 선택하세요.</h3>
    <div>
      <ul>
        <li v-for="(quiz, index) in quizs" :key="index">
          <table :class="{ disabled: quiz.temp.length === 2 }">
            <tr>
              <td>유형</td>
              <td>{{ quiz.type }}</td>
            </tr>
            <tr>
              <td>번호</td>
              <td>{{ quiz.number }}</td>
            </tr>
            <tr>
              <td>문제</td>
              <td>
                <span v-html="getQuestionPart(quiz.question, 0)" />
                <span
                  v-html="quiz.answer1"
                  class="list-group-item-answer"
                  @click="clearAnswer1(index)"
                />
                <span v-html="getQuestionPart(quiz.question, 1)" />
                <span
                  v-html="quiz.answer2"
                  class="list-group-item-answer"
                  @click="clearAnswer2(index)"
                />
                <span v-html="getQuestionPart(quiz.question, 2)" />
              </td>
            </tr>
            <tr>
              <td>
                <div v-if="quiz.exams[0] === quiz.answer1">1</div>
                <div v-if="quiz.exams[0] === quiz.answer2">2</div>
                <input
                  type="checkbox"
                  :name="'answer' + quiz.number"
                  v-model="quiz.temp"
                  :value="quiz.exams[0]"
                  @change="setAnswer(index)"
                />
              </td>
              <td @click="checkAnswer(index, quiz.exams[0])" class="exam-word">
                {{ quiz.exams[0] }}
              </td>
            </tr>
            <tr>
              <td>
                <div v-if="quiz.exams[1] === quiz.answer1">1</div>
                <div v-if="quiz.exams[1] === quiz.answer2">2</div>
                <input
                  type="checkbox"
                  :name="'answer' + quiz.number"
                  v-model="quiz.temp"
                  :value="quiz.exams[1]"
                  @change="setAnswer(index)"
                />
              </td>
              <td @click="checkAnswer(index, quiz.exams[1])" class="exam-word">
                {{ quiz.exams[1] }}
              </td>
            </tr>
            <tr>
              <td>
                <div v-if="quiz.exams[2] === quiz.answer1">1</div>
                <div v-if="quiz.exams[2] === quiz.answer2">2</div>
                <input
                  type="checkbox"
                  :name="'answer' + quiz.number"
                  v-model="quiz.temp"
                  :value="quiz.exams[2]"
                  @change="setAnswer(index)"
                />
              </td>
              <td @click="checkAnswer(index, quiz.exams[2])" class="exam-word">
                {{ quiz.exams[2] }}
              </td>
            </tr>
            <tr>
              <td>
                <div v-if="quiz.exams[3] === quiz.answer1">1</div>
                <div v-if="quiz.exams[3] === quiz.answer2">2</div>
                <input
                  type="checkbox"
                  :name="'answer' + quiz.number"
                  v-model="quiz.temp"
                  :value="quiz.exams[3]"
                  @change="setAnswer(index)"
                />
              </td>
              <td @click="checkAnswer(index, quiz.exams[3])" class="exam-word">
                {{ quiz.exams[3] }}
              </td>
            </tr>
            <tr>
              <td>
                <div v-if="quiz.exams[4] === quiz.answer1">1</div>
                <div v-if="quiz.exams[4] === quiz.answer2">2</div>
                <input
                  type="checkbox"
                  :name="'answer' + quiz.number"
                  v-model="quiz.temp"
                  :value="quiz.exams[4]"
                  @change="setAnswer(index)"
                />
              </td>
              <td @click="checkAnswer(index, quiz.exams[4])" class="exam-word">
                {{ quiz.exams[4] }}
              </td>
            </tr>
            <tr>
              <td>
                <div v-if="quiz.exams[5] === quiz.answer1">1</div>
                <div v-if="quiz.exams[5] === quiz.answer2">2</div>
                <input
                  type="checkbox"
                  :name="'answer' + quiz.number"
                  v-model="quiz.temp"
                  :value="quiz.exams[5]"
                  @change="setAnswer(index)"
                />
              </td>
              <td @click="checkAnswer(index, quiz.exams[5])" class="exam-word">
                {{ quiz.exams[5] }}
              </td>
            </tr>
          </table>
        </li>
      </ul>
    </div>
    <div v-if="isLoading" class="loading-container">
      <div class="loading">
        <FadeLoader />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import config from "@/global/Config";

export default {
  name: "QuizType3",
  components: {
    FadeLoader,
  },
  props: {
    token: String,
  },
  data() {
    return {
      quizs: [],
      isLoading: false,
    };
  },
  computed: {
    apiUrl() {
      return `${config.api_server_url}/test`;
    },
    param() {
      return {
        token: this.token,
        quiz_type: 3,
      };
    },
  },
  async mounted() {
    this.isLoading = true;

    let shuffle = (array) => {
      array.sort(() => Math.random() - 0.5);
    };

    await axios
      .post(this.apiUrl, this.param)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          this.quizs = response.data;

          this.quizs.map((quiz) => {
            let temp = [];
            temp.push(quiz.answer1);
            temp.push(quiz.answer2);
            temp.push(quiz.exam1);
            temp.push(quiz.exam2);
            temp.push(quiz.exam3);
            temp.push(quiz.exam4);

            shuffle(temp);

            quiz["exams"] = temp;
            quiz["show_text"] = quiz.question;
            quiz["answer1"] = "_____";
            quiz["answer2"] = "_____";

            // console.log(quiz.question, quiz.temp.length, quiz.temp)
            if (quiz.temp.length === 0) {
              quiz.temp.push("")
              quiz.temp.push("")
            }

            if (quiz.temp.length == 2) {
              if (quiz["temp"][0].length > 0) {
                quiz["answer1"] = quiz["temp"][0];
              }

              if (quiz.temp[1].length > 0) {
                quiz["answer2"] = quiz["temp"][1];
              }

              // quiz.temp = quiz.temp.filter((item) => item.length > 0);
            }
          });

          this.updateNoTouchQuizCount();
        }

        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error);

        this.isLoading = false;
      });
  },
  methods: {
    changeToeicScore(index, quizNumber, score) {
      this.quizs[index].score = score;
      this.$emit("updateToeicScore", 3, quizNumber, score);
    },
    checkAnswer(index, answer) {
      const quiz = this.quizs[index];

      if (quiz.temp[0] === answer) {
        quiz.temp[0] = ""
      }
      else if (quiz.temp[1] === answer) {
        quiz.temp[1] = ""
      } else {
        if (quiz.temp[0].length === 0) {
          quiz.temp[0] = answer;
        } else {
          quiz.temp[1] = answer;
        }
      }

      this.setAnswer(index);
    },
    setAnswer(index) {
      const quiz = this.quizs[index];

      if (quiz.temp.length > 2) {
        quiz.temp.splice(2, 1);
        return;
      }

      console.log(quiz.temp.length);
      quiz.temp.map((item) => console.log(item))

      quiz.answer1 = quiz.temp[0].length > 0 ? quiz.temp[0] : "_____";
      quiz.answer2 = quiz.temp[1].length > 0 ? quiz.temp[1] : "_____";

      this.updateAnswer(index);
    },
    getQuestionPart(text, part) {
      const list = text.split("_____");
      return list[part];
    },
    clearAnswer1(index) {
      const quiz = this.quizs[index];
      quiz.temp[0] = ""
      quiz.answer1 = "_____";
      this.updateAnswer(index);
    },
    clearAnswer2(index) {
      const quiz = this.quizs[index];
      quiz.temp[1] = ""
      quiz.answer2 = "_____";
      this.updateAnswer(index);
    },
    updateAnswer(index) {
      const quiz = this.quizs[index];
      let answerString = `${quiz.temp[0]}**${quiz.temp[1]}`;

      this.$emit("setAnswer", this.token, 3, quiz.number, answerString);
      this.updateNoTouchQuizCount();
    },
    updateNoTouchQuizCount() {
      this.$store.commit(
        "setCountType3",
        this.quizs.filter((item) => item.temp[0].length === 0 || item.temp[1].length === 0).length
      );
    },
  },
};
</script>

<style scoped>
.loading {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 9999px;
}

ul {
  list-style-type: none;
}

li:before {
  content: none;
}

table {
  border: 1px solid #eee;
  border-collapse: collapse;
  margin: 0.4rem;
}

td {
  border: 1px solid red;
  padding: 0.5rem;
}

.list-group-item-answer {
  text-decoration: underline;
  color: red;
}

.list-group-item-answer:hover {
  cursor: pointer;
}

.list-group-item-from {
  padding: 0.2rem 0.5rem;
  width: 2rem;
}

.list-group-item-to {
  padding: 0.2rem;
  text-decoration: underline;
  font-size: 1.1rem;
}

.item:hover {
  cursor: pointer;
  border: 1px solid #aaa;
  border-radius: 1rem;
  background-color: rgba(100, 176, 255, 0.2);
}

.list-group {
  display: inline-block;
}

.exam-word:hover {
  cursor: pointer;
}

table.disabled td {
  border: 1px solid green;
}
</style>
