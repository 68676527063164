import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import HelloWorld from "./components/HelloWorld.vue";
import stores from './global/store.js'

const routes = [
    { path: '/', component:HelloWorld, props: true},
    { path: '/:url_token', component:HelloWorld, props: true},
]

// 라우터 생성
const router = createRouter({
    history: createWebHistory(),
    routes
});

createApp(App).use(router).use(stores).mount('#app')
