<template>
  <div class="login">
    <div v-if="viewStatus === 0">
      <h2>발급 받은 토큰을 입력해 주세요</h2>
      <input type="edit" size="30" v-model="token" />
      <div class="button" @click="setToken">문제 풀기로 가기</div>
      <h3 @click="viewStatus = 1">새로운 토큰 발급 받기</h3>
    </div>
    <div v-if="viewStatus === 1">
      <h2>토익 점수대를 선택해 주세요</h2>
      <ul>
        <li>
          <input type="radio" name="toeic-score" v-model="toeicScore" value="0" />모름
        </li>
        <li>
          <input type="radio" name="toeic-score" v-model="toeicScore" value="500" />500
          점대
        </li>
        <li>
          <input type="radio" name="toeic-score" v-model="toeicScore" value="600" />600
          점대
        </li>
        <li>
          <input type="radio" name="toeic-score" v-model="toeicScore" value="700" />700
          점대
        </li>
        <li>
          <input type="radio" name="toeic-score" v-model="toeicScore" value="800" />800
          점대
        </li>
        <li>
          <input type="radio" name="toeic-score" v-model="toeicScore" value="900" />900
          점대
        </li>
      </ul>
      <div class="button" @click="getToken">토큰 받기!!</div>
    </div>
    <div v-if="viewStatus === 2">
      <h2>토큰 번호나 URL 을 저장해 주세요!</h2>
      <div class="token-id">
        토큰 : <span class="token">{{ token }}</span>
      </div>
      <div class="token-url-id">
        바로 접근용 URL :
        <span @click="goTokenUrl" class="token-url">{{ token_url }}</span>
      </div>
      <div class="button" @click="viewStatus = 0">로그인 페이지로 돌아 가기</div>
    </div>
    <div v-if="isLoading" class="loading-container">
      <div class="loading">
        <FadeLoader />
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/global/Config";
import axios from "axios";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";

export default {
  name: "LoginPage",
  components: {
    FadeLoader,
  },
  data() {
    return {
      toeicScore: 0,
      viewStatus: 0,
      token: "",
      token_url: "",
      isLoading: false,
    };
  },
  computed: {
    apiUrl() {
      return `${config.api_server_url}/create_member`;
    },
    feUrl() {
      return config.fe_url;
    },
  },
  methods: {
    setToken() {
      this.$emit("setToken", this.token.trim());
    },
    async getToken() {
      this.isLoading = true;

      await axios
        .post(this.apiUrl, { score: this.toeicScore })
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            this.token = response.data.token;
            this.token_url = `${this.feUrl}/${this.token}`;
            this.viewStatus = 2;
          }

          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);

          this.isLoading = false;
        });
    },
    goTokenUrl() {
      console.log(this.token_url);
      location.href = this.token_url;
    },
  },
};
</script>

<style>
input {
  border-radius: 0.5rem;
  height: 2rem;
}

h3 {
  margin-top: 3rem;
  text-decoration: underline;
}

h3:hover {
  cursor: pointer;
  color: #999;
}

li {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.button {
  display: inline-block;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  text-decoration: underline;
  margin-top: 2rem;
}

.button:hover {
  font-weight: bold;
  cursor: pointer;
}

.token-id,
.token-url-id {
  font-size: 1.2rem;
  margin-top: 1rem;
}

.token-id {
  display: inline-block;
}

.token {
  background-color: #eee;
  font-weight: bold;
}

.token-url {
  background-color: #eee;
  font-weight: bold;
}

.token-url:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
