<template>
<div>
  <h1>퀴즈 유형 8</h1>
  <div>
    <ul>
      <li v-for="(quiz, index) in quizs" :key="index">
        <table>
          <tr><td>유형</td><td>{{quiz.type}}</td></tr>
          <tr><td>번호</td><td>{{quiz.number}}</td></tr>
          <tr><td>음성 파일</td><td>{{quiz.audio_file}}
            <br>
            <audio controls><source :src="quiz.audio_file" type="audio/mpeg"></audio></td></tr>
          <tr><td>보기 1</td><td><input type="radio" :name="'answer'+quiz.number" v-model="quiz.temp" :value="quiz.exams[0]" @change="putAnswer(index, quiz.number, quiz.exams[0])">{{quiz.exams[0]}}</td></tr>
          <tr><td>보기 2</td><td><input type="radio" :name="'answer'+quiz.number" v-model="quiz.temp" :value="quiz.exams[1]" @change="putAnswer(index, quiz.number, quiz.exams[1])">{{quiz.exams[1]}}</td></tr>
          <tr><td>보기 3</td><td><input type="radio" :name="'answer'+quiz.number" v-model="quiz.temp" :value="quiz.exams[2]" @change="putAnswer(index, quiz.number, quiz.exams[2])">{{quiz.exams[2]}}</td></tr>
          <tr><td>보기 4</td><td><input type="radio" :name="'answer'+quiz.number" v-model="quiz.temp" :value="quiz.exams[3]" @change="putAnswer(index, quiz.number, quiz.exams[3])">{{quiz.exams[3]}}</td></tr>
        </table>
      </li>
    </ul>
  </div>
  <div v-if="isLoading" class="loading-container">
    <div class="loading">
      <FadeLoader />
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import FadeLoader from 'vue-spinner/src/FadeLoader.vue'
import config from '@/global/Config'

export default {
  name: "QuizType8",
  components: {
    FadeLoader,
  },
  data() {
    return {
      quizs: [],
      isLoading: false,
    }
  },
  computed: {
    apiUrl() {
      return `${config.api_server_url}/quiz/all/8/${config.id_number}`
    }
  },
  async mounted() {
    this.isLoading = true

    let shuffle = (array) => {
      array.sort(() => Math.random() - 0.5);
    }

    await axios.get(this.apiUrl)
      .then(response => {
        if (response.status === 200) {
          console.log(response.data)
          this.quizs = response.data

          this.quizs.map(quiz => {
            let temp = []
            temp.push(quiz.answer)
            temp.push(quiz.exam1)
            temp.push(quiz.exam2)
            temp.push(quiz.exam3)

            shuffle(temp)

            quiz['exams'] = temp
          })
        }

        this.isLoading = false
      })
      .catch(error => {
        console.log(error)

        this.isLoading = false
      })
  },
  methods: {
    changeToeicScore(index, quizNumber, score) {
      this.quizs[index].score = score
      this.$emit('updateToeicScore', 8, quizNumber, score)
    },
    putAnswer(index, quizNumber, answer) {
      this.quizs[index].temp = answer
      this.$emit('putAnswer', 8, quizNumber, answer, "")
    },
  }
}
</script>

<style scoped>
.loading {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 9999px;
}

ul {
  list-style-type: none;
}

li:before {
  content: none;
}

table {
  border: 1px solid #eee;
  border-collapse: collapse;
  margin: .4rem;
}

td {
  border: 1px solid;
  padding: .5rem;
}
</style>
